<template>
  <div v-if="_.isPlainObject($store.state.profile) && $store.state.patient" class="report">
    <v-toolbar flat dense>
      <v-row :style="{ flex: `0 0 ${$vuetify.breakpoint.mobile ? (48 + 4) * 1 : 240}px` }">
        <v-spacer />
      </v-row>
      <v-spacer />
      <v-toolbar-title>報告</v-toolbar-title>
      <v-spacer />
      <v-row :style="{ flex: `0 0 ${$vuetify.breakpoint.mobile ? (48 + 4) * 1 : 240}px` }">
        <v-spacer />
        <print-icon-button class="ml-1" />
      </v-row>
    </v-toolbar>
    <div
      v-if="$store.state.measurementRecords && $store.state.measurementRecords.length > 0"
      class="overflow-y-auto"
      style="height: calc(100vh - 96px)"
    >
      <v-row no-gutters class="pa-0 pa-sm-2" :style="scaleAndStyle.style">
        <v-col cols="12" md="6">
          <div class="ma-0 pa-sm-3">
            <v-card
              :class="`flex-grow-1 deep-purple ${colorKey}`"
              :flat="$vuetify.breakpoint.mobile"
              :rounded="$vuetify.breakpoint.mobile ? '0' : ''"
            >
              <v-card-title>基本資料</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      label="姓名"
                      dense
                      readonly
                      hide-details
                      :value="$store.state.patient.profile.fullName"
                    />
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-text-field
                      label="身分證／居留證號"
                      dense
                      readonly
                      hide-details
                      :value="$store.state.patient.profile.idNumber"
                    />
                  </v-col>
                  <v-col cols="12" sm="5">
                    <v-text-field
                      label="生日"
                      dense
                      readonly
                      hide-details
                      :value="birthDateString"
                      :suffix="ageString"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
          <div class="ma-0 pa-sm-3">
            <v-card
              :class="`flex-grow-1 green ${colorKey}`"
              :flat="$vuetify.breakpoint.mobile"
              :rounded="$vuetify.breakpoint.mobile ? '0' : ''"
            >
              <v-card-title>量測統計</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="6" sm="3">
                    <v-text-field
                      label="日間起始時間"
                      dense
                      readonly
                      hide-details
                      :value="
                        Date$.localizedHourStrings[measure.results.statistics.daytimeBeginHour]
                      "
                    />
                  </v-col>
                  <v-col cols="6" sm="3">
                    <v-text-field
                      label="夜間起始時間"
                      dense
                      readonly
                      hide-details
                      :value="
                        Date$.localizedHourStrings[measure.results.statistics.nighttimeBeginHour]
                      "
                    />
                  </v-col>
                  <v-col cols="6" sm="3">
                    <v-text-field
                      label="量測次數"
                      dense
                      readonly
                      hide-details
                      :value="`${measure.results.statistics.measureCount}次`"
                    />
                  </v-col>
                  <v-col cols="6" sm="3">
                    <v-text-field
                      label="量測成功率"
                      dense
                      readonly
                      hide-details
                      :value="`${(measure.results.statistics.measureSuccessRate * 100).toFixed()}%`"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
          <div class="ma-0 pa-sm-3">
            <v-card
              :class="`flex-grow-1 blue ${colorKey}`"
              :flat="$vuetify.breakpoint.mobile"
              :rounded="$vuetify.breakpoint.mobile ? '0' : ''"
            >
              <v-card-title>平均血壓</v-card-title>
              <v-card-text>
                <v-simple-table dense :class="`blue ${colorKey}`">
                  <template #default>
                    <thead>
                      <tr>
                        <th />
                        <th class="text-center">收縮壓</th>
                        <th class="text-center">舒張壓</th>
                        <th class="text-center">心率</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(label, time) in {
                          allTimes: '整天',
                          daytime: '日間',
                          nighttime: '夜間',
                        }"
                        :key="`means-${time}`"
                      >
                        <td class="text-center">{{ label }}</td>
                        <template v-if="measure.results.means[time] !== null">
                          <td
                            v-for="(mean, key) in measure.results.means[time]"
                            :key="`means-${time}-${key}`"
                            class="text-center"
                          >
                            {{ mean.toFixed() }}
                          </td>
                        </template>
                        <template v-else>
                          <td class="text-center" colspan="3">無資料</td>
                        </template>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </div>

          <div class="ma-0 pa-sm-3">
            <v-card
              :class="`flex-grow-1 cyan ${colorKey}`"
              :flat="$vuetify.breakpoint.mobile"
              :rounded="$vuetify.breakpoint.mobile ? '0' : ''"
            >
              <v-card-title>夜間血壓下降</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col
                    v-for="(label, key) in {
                      systolic: '收縮壓',
                      diastolic: '舒張壓',
                    }"
                    :key="`bloodPressureNighttimeDropRates-${key}`"
                    cols="12"
                    sm="4"
                  >
                    <v-text-field
                      :label="label"
                      dense
                      readonly
                      hide-details
                      :value="
                        measure.results.bloodPressureNighttimeDropRates[key] !== null
                          ? `${(
                              measure.results.bloodPressureNighttimeDropRates[key] * 100
                            ).toFixed()}%`
                          : '資料不足'
                      "
                    />
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      label="晨間血壓上升"
                      dense
                      readonly
                      hide-details
                      :value="
                        measure.results.bloodPressureNighttimeDropRates.areRisedBeginEachDaytime
                          .length === 0
                          ? '資料不足'
                          : measure.results.bloodPressureNighttimeDropRates.areRisedBeginEachDaytime
                              .map((b) => (b ? '有' : '無'))
                              .join('、')
                      "
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
          <div class="ma-0 pa-sm-3">
            <v-card
              :class="`flex-grow-1 lime ${colorKey}`"
              :flat="$vuetify.breakpoint.mobile"
              :rounded="$vuetify.breakpoint.mobile ? '0' : ''"
            >
              <v-card-title>高血壓負荷</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col
                    v-for="(label, time) in { daytime: '日間', nighttime: '夜間' }"
                    :key="`hypertensionLoads-${time}`"
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      :label="label"
                      dense
                      readonly
                      hide-details
                      :value="
                        measure.results.hypertensionLoads[time].rate !== null
                          ? `${(measure.results.hypertensionLoads[time].rate * 100).toFixed(2)}%`
                          : '無資料'
                      "
                    >
                      <template #append>
                        （>{{ measure.results.hypertensionLoads[time].systolicThreshold }}/{{
                          measure.results.hypertensionLoads[time].diastolicThreshold
                        }}）
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
          <div class="ma-0 pa-sm-3">
            <v-card
              :class="`flex-grow-1 amber ${colorKey}`"
              :flat="$vuetify.breakpoint.mobile"
              :rounded="$vuetify.breakpoint.mobile ? '0' : ''"
            >
              <v-card-title>血壓變異／脈搏硬度</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="6" sm="3">
                    <v-text-field
                      label="SD"
                      dense
                      readonly
                      hide-details
                      :value="measure.results.bloodPressureVariances.sd.toFixed(2)"
                    />
                  </v-col>
                  <v-col cols="6" sm="3">
                    <v-text-field
                      label="CV"
                      dense
                      readonly
                      hide-details
                      :value="`${(measure.results.bloodPressureVariances.cv * 100).toFixed(2)}%`"
                    />
                  </v-col>
                  <v-col cols="6" sm="3">
                    <v-text-field
                      label="ARV"
                      dense
                      readonly
                      hide-details
                      :value="measure.results.bloodPressureVariances.arv.toFixed(2)"
                    />
                  </v-col>
                  <v-col cols="6" sm="3">
                    <v-text-field
                      label="AASI"
                      dense
                      readonly
                      hide-details
                      :value="measure.results.bloodPressureVariances.aasi.toFixed(2)"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="ma-0 pa-sm-3">
            <v-card
              :class="`flex-grow-1 indigo ${colorKey}`"
              :flat="$vuetify.breakpoint.mobile"
              :rounded="$vuetify.breakpoint.mobile ? '0' : ''"
            >
              <v-card-title>紀錄</v-card-title>
              <v-card-text>
                <v-simple-table dense fixed-header :class="`indigo ${colorKey}`" height="396px">
                  <template #default>
                    <thead>
                      <tr>
                        <th :class="`text-center indigo ${colorKey}`" colspan="2">時間</th>
                        <th :class="`text-center indigo ${colorKey}`">收縮壓</th>
                        <th :class="`text-center indigo ${colorKey}`">舒張壓</th>
                        <th :class="`text-center indigo ${colorKey}`">心率</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(row, i) in measure.results.recordsTable"
                        :key="`recordsTableRow-${i}`"
                      >
                        <template v-if="row.type === 'date'">
                          <td colspan="5" class="text-center font-weight-bold">
                            {{ row.values[0] }}
                          </td>
                        </template>
                        <template v-else>
                          <td class="text-end">{{ row.values[0] }}</td>
                          <td class="text-start">{{ row.values[1] }}</td>
                          <template v-if="row.type === 'success'">
                            <td
                              v-for="j in [2, 3, 4]"
                              :key="`recordsTableRow-success-${j}`"
                              class="text-center"
                            >
                              <span v-if="typeof row.values[j] === 'number'">
                                {{ row.values[j] }}
                              </span>
                              <span v-else-if="!row.values[j].isOver">
                                {{ row.values[j].value }}
                              </span>
                              <span v-else style="color: var(--v-error-base)">
                                {{ row.values[j].value }}
                              </span>
                            </td>
                          </template>
                          <td
                            v-else
                            colspan="3"
                            style="color: var(--v-error-base)"
                            class="text-center font-italic"
                          >
                            {{ row.values[2] }}
                          </td>
                        </template>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </div>
          <div class="ma-0 pa-sm-3">
            <v-card
              :class="`flex-grow-1 orange ${colorKey}`"
              :flat="$vuetify.breakpoint.mobile"
              :rounded="$vuetify.breakpoint.mobile ? '0' : ''"
            >
              <v-card-title>趨勢</v-card-title>
              <v-card-text>
                <trends-chart id="trends-chart" v-model="chart" :options="chartOptions" />
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </div>
    <div
      v-else
      class="pa-3 d-flex align-center justify-center text-h6 text-sm-h4 text--disabled"
      style="height: calc(100vh - 96px)"
    >
      沒有量測紀錄來產生報告
    </div>
  </div>
</template>

<style lang="scss" scoped>
#trends-chart {
  position: relative;
  height: 298px;
}
</style>

<script>
import Vue from 'vue';
import * as dateTimeString from '@/utils/dateTimeString';
import { Date } from '@/extensions';

import PrintIconButton from '@/components/Report/PrintIconButton';
import TrendsChart from '@/components/TrendsChart';
import extensions from '@/mixins/extensions';
import measure from '@/mixins/measure';

export default Vue.extend({
  name: 'Report',
  components: {
    PrintIconButton,
    TrendsChart,
  },
  mixins: [extensions, measure],
  data: () => ({
    chart: null,
  }),
  computed: {
    birthDateString() {
      return dateTimeString.toDate(this.$store.state.patient.profile.birthDate).rocDateString;
    },
    ageString() {
      const birthDate = dateTimeString.toDate(this.$store.state.patient.profile.birthDate);
      const age = (() => {
        const today = Date.withoutSeconds();
        const age = today.getFullYear() - birthDate.getFullYear();
        const month = today.getMonth() - birthDate.getMonth();
        if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) return age - 1;
        return age;
      })();
      return `（${age}歲）`;
    },
    colorKey() {
      return this.$vuetify.theme.dark ? 'darken-4' : 'lighten-4';
    },
    scaleAndStyle() {
      const breakpoint = this.$vuetify.breakpoint;
      if (breakpoint.smAndDown) return {};
      const height = 918;
      const widthBase = breakpoint.mdAndDown
        ? breakpoint.thresholds.sm
        : breakpoint.lgAndDown
        ? breakpoint.thresholds.md
        : breakpoint.thresholds.lg;
      let width = widthBase - 56;
      let widthAfterScaling = breakpoint.width - 56;
      const scrollbarWidth = this.getScrollbarWidth();
      if (scrollbarWidth > 0 && height * (widthAfterScaling / width) + 96 > breakpoint.height) {
        width = widthBase - 56 - scrollbarWidth;
        widthAfterScaling = breakpoint.width - 56 - scrollbarWidth;
      }
      const scale = widthAfterScaling / width;
      return {
        scale: scale,
        style: {
          width: `${width}px`,
          height: `${height}px`,
          transformOrigin: 'top left',
          transform: `scale(${scale})`,
        },
      };
    },
    chartOptions() {
      // TODO: realtime update scale of chart
      return {
        devicePixelRatio: (this.scaleAndStyle.scale ?? 1) * window.devicePixelRatio,
        legend: {
          onClick: null,
        },
      };
    },
  },
  methods: {
    getScrollbarWidth() {
      // Reference: https://stackoverflow.com/a/13382873
      const outer = document.createElement('div');
      outer.style.visibility = 'hidden';
      outer.style.overflow = 'scroll';
      outer.style.msOverflowStyle = 'scrollbar';
      document.body.appendChild(outer);
      const inner = document.createElement('div');
      outer.appendChild(inner);
      const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
      outer.parentNode.removeChild(outer);
      return scrollbarWidth;
    },
  },
});
</script>
