import Vue from 'vue';

import Measure from '@/models/Measure';

export default Vue.extend({
  data: () => ({
    measure: new Measure(),
  }),
  watch: {
    ['$store.state.patient']: {
      handler() {
        this.updateMeasure();
      },
      deep: true,
    },
    ['$store.state.measurementRecords']: {
      handler() {
        this.updateMeasure();
      },
      deep: true,
    },
  },
  created() {
    this.updateMeasure();
  },
  methods: {
    updateMeasure() {
      if (
        this.$store.state.patient &&
        this.$store.state.measurementRecords &&
        this.$store.state.measurementRecords.length > 0
      ) {
        this.measure.update(
          this.$store.state.patient.bloodPressureGoals,
          this.$store.state.measurementRecords,
        );
      } else {
        this.measure.clear();
      }
    },
  },
});
