<template>
  <div class="print-icon-button">
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn
          :rounded="!$vuetify.breakpoint.mobile"
          :text="!$vuetify.breakpoint.mobile"
          :outlined="!$vuetify.breakpoint.mobile"
          :icon="$vuetify.breakpoint.mobile"
          :disabled="
            !$store.state.measurementRecords || $store.state.measurementRecords.length === 0
          "
          v-bind="attrs"
          v-on="on"
          @click="print()"
        >
          <v-icon :left="!$vuetify.breakpoint.mobile">mdi-printer</v-icon>
          <template v-if="!$vuetify.breakpoint.mobile">列印</template>
        </v-btn>
      </template>
      <span>列印</span>
    </v-tooltip>
    <!-- 192mm * 280mm -->
    <div
      v-if="$store.state.measurementRecords && $store.state.measurementRecords.length > 0"
      id="for-printing"
      class="d-none"
    >
      <div class="report">
        <div class="title">24小時居家血壓量測報告</div>
        <div class="blocks row1">
          <div class="block" style="background-color: #d1c4e9">
            <div class="block-rows">
              <div class="block-title">基本資料</div>
              <div class="block-row center">
                <div class="block-value profile">{{ $store.state.patient.profile.fullName }}</div>
              </div>
              <div class="block-row center">
                <div class="block-value profile">{{ $store.state.patient.profile.idNumber }}</div>
              </div>
              <div class="block-row center">
                <div class="block-value profile">{{ localizedAgeString }}</div>
              </div>
            </div>
          </div>
          <div class="block" style="background-color: #bbdefb">
            <div class="block-rows">
              <div class="block-title">平均血壓</div>
              <div
                v-for="(label, time) in {
                  allTimes: '',
                  daytime: '日間',
                  nighttime: '夜間',
                }"
                :key="`blockRow-means-${time}`"
                class="block-row"
              >
                <div v-if="time === 'allTimes'" class="block-value label large">{{ label }}</div>
                <div v-else class="block-value label">{{ label }}</div>
                <div v-if="time === 'allTimes'" class="block-value large">
                  {{
                    measure.results.means[time] !== null
                      ? measure.results.means[time].systolicBloodPressure.toFixed() +
                        '/' +
                        measure.results.means[time].diastolicBloodPressure.toFixed() +
                        '  ' +
                        measure.results.means[time].heartRate.toFixed()
                      : 'N/A'
                  }}
                </div>
                <div v-else class="block-value">
                  {{
                    measure.results.means[time] !== null
                      ? measure.results.means[time].systolicBloodPressure.toFixed() +
                        '/' +
                        measure.results.means[time].diastolicBloodPressure.toFixed() +
                        '  ' +
                        measure.results.means[time].heartRate.toFixed()
                      : 'N/A'
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="block" style="background-color: #b2ebf2">
            <div class="block-rows">
              <div class="block-title">夜間血壓下降</div>
              <div
                v-for="(label, key) in {
                  systolic: '收縮壓',
                  diastolic: '舒張壓',
                }"
                :key="`blockRow-bloodPressureNighttimeDropRates-${key}`"
                class="block-row"
              >
                <div v-if="key === 'systolic'" class="block-value label large">{{ label }}</div>
                <div v-else class="block-value label">{{ label }}</div>
                <div v-if="key === 'systolic'" class="block-value large">
                  {{ (measure.results.bloodPressureNighttimeDropRates[key] * 100).toFixed() }}%
                </div>
                <div v-else class="block-value">
                  {{ (measure.results.bloodPressureNighttimeDropRates[key] * 100).toFixed() }}%
                </div>
              </div>
              <div class="block-row">
                <div class="block-value label">晨間血壓上升</div>
                <div>
                  {{
                    measure.results.bloodPressureNighttimeDropRates.areRisedBeginEachDaytime
                      .length === 0
                      ? '資料不足'
                      : measure.results.bloodPressureNighttimeDropRates.areRisedBeginEachDaytime
                          .map((b) => (b ? '有' : '無'))
                          .join('、')
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="blocks row2">
          <div class="block" style="background-color: #c8e6c9">
            <div class="block-rows">
              <div class="block-title">量測統計</div>
              <div class="block-row center">
                <div class="block-value label">日間時間範圍</div>
                <div class="block-value label">夜間時間範圍</div>
              </div>
              <div class="block-row center">
                <div class="block-value">
                  {{ measure.results.statistics.daytimeBeginHour }}時～{{
                    measure.results.statistics.nighttimeBeginHour
                  }}時
                </div>
                <div class="block-value">
                  {{ measure.results.statistics.nighttimeBeginHour }}時～{{
                    measure.results.statistics.daytimeBeginHour
                  }}時
                </div>
              </div>
              <div class="block-row">
                <div class="block-value label">量測次數</div>
                <div class="block-value">{{ `${measure.results.statistics.measureCount}次` }}</div>
              </div>
              <div class="block-row">
                <div class="block-value label">量測成功率</div>
                <div class="block-value">
                  {{ (measure.results.statistics.measureSuccessRate * 100).toFixed() }}%
                </div>
              </div>
            </div>
          </div>
          <div class="block" style="background-color: #f0f4c3">
            <div class="block-rows">
              <div class="block-title">高血壓負荷</div>
              <div
                v-for="(label, time) in { daytime: '日間', nighttime: '夜間' }"
                :key="`blockRow-hypertensionLoads-${time}`"
                class="block-row"
              >
                <div class="block-value label large">{{ label }}</div>
                <div class="block-value" style="line-height: 13mm">
                  (>{{ measure.results.hypertensionLoads[time].systolicThreshold }}/{{
                    measure.results.hypertensionLoads[time].diastolicThreshold
                  }})
                </div>
                <div class="block-value large">
                  {{
                    measure.results.hypertensionLoads[time].rate !== null
                      ? `${(measure.results.hypertensionLoads[time].rate * 100).toFixed()}%`
                      : 'N/A'
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="block" style="background-color: #ffecb3">
            <div class="block-rows">
              <div class="block-title">血壓變異</div>
              <div class="block-row">
                <div class="block-value label small">SD</div>
                <div class="block-value small">
                  {{ measure.results.bloodPressureVariances.sd.toFixed(2) }}
                </div>
                <div class="block-value label small">CV</div>
                <div class="block-value small">
                  {{ (measure.results.bloodPressureVariances.cv * 100).toFixed(2) }}%
                </div>
                <div class="block-value label small">ARV</div>
                <div class="block-value small">
                  {{ measure.results.bloodPressureVariances.arv.toFixed(2) }}
                </div>
              </div>
              <div class="block-title">動脈硬度</div>
              <div class="block-row">
                <div class="block-value label large">AASI</div>
                <div class="block-value large">
                  {{ measure.results.bloodPressureVariances.aasi.toFixed(2) }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <img class="trends" :src="chartBase64Image" />
        <template v-for="i in 2">
          <div
            :key="`recordsPage-${i}`"
            :style="{
              position: 'absolute',
              top: i < 2 ? '165mm' : '297mm',
            }"
          >
            <div class="records header">
              <div v-for="j in 2" :key="`recordHeader-${j}`" class="record-header">
                <div class="record-header-value date">時間</div>
                <div class="record-header-value numbers">
                  <div class="record-header-value number">收縮壓</div>
                  <div class="record-header-value number">舒張壓</div>
                  <div class="record-header-value number">心率</div>
                  <div class="record-header-value units">
                    <div class="record-header-value unit">(mmHg)</div>
                    <div class="record-header-value unit">(mmHg)</div>
                    <div class="record-header-value unit">(bpm)</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="records body" :style="{ height: i < 2 ? '105mm' : '260mm' }">
              <template v-for="(row, j) in measure.results.recordsTable">
                <div v-if="i < 2 ? j < 42 : j >= 42" :key="`recordData-${j}`" class="record-data">
                  <div v-if="row.type === 'date'" class="record-data-value date">
                    {{ row.values[0] }}
                  </div>
                  <template v-else>
                    <div class="record-data-value midday">{{ row.values[0] }}</div>
                    <div class="record-data-value time">{{ row.values[1] }}</div>
                    <template v-if="row.type === 'success'">
                      <template v-for="k in [2, 3, 4]">
                        <div
                          v-if="typeof row.values[k] === 'number'"
                          :key="`recordDataRow-success-${k}`"
                          class="record-data-value number"
                        >
                          {{ row.values[k] }}
                        </div>
                        <div
                          v-else-if="!row.values[k].isOver"
                          :key="`recordDataRow-success-${k}`"
                          class="record-data-value number"
                        >
                          {{ row.values[k].value }}
                        </div>
                        <div
                          v-else
                          :key="`recordDataRow-success-${k}`"
                          class="record-data-value number over"
                        >
                          {{ row.values[k].value }}
                        </div>
                      </template>
                    </template>
                    <div v-else class="record-data-value error">{{ row.values[2] }}</div>
                  </template>
                </div>
              </template>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div style="position: fixed; visibility: hidden">
      <trends-chart id="trends-chart" v-model="chart" :options="chartOptions" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
#trends-chart {
  position: relative;
  width: 192mm;
  height: 80mm;
}
</style>

<script>
import Vue from 'vue';
import * as dateTimeString from '@/utils/dateTimeString';
import { Date } from '@/extensions';

import TrendsChart from '@/components/TrendsChart';
import extensions from '@/mixins/extensions';
import measure from '@/mixins/measure';

export default Vue.component(
  'print-icon-button',
  Vue.extend({
    name: 'PrintIconButton',
    components: {
      TrendsChart,
    },
    mixins: [extensions, measure],
    data: () => ({
      value: {},
      chartBase64Image: '',
      chart: null,
      chartOptions: {
        devicePixelRatio: 8,
        legend: {
          onClick: null,
        },
        tooltips: {
          enabled: false,
        },
      },
    }),
    computed: {
      localizedAgeString() {
        const birthDate = dateTimeString.toDate(this.$store.state.patient.profile.birthDate);
        const age = (() => {
          const today = Date.withoutSeconds();
          const age = today.getFullYear() - birthDate.getFullYear();
          const month = today.getMonth() - birthDate.getMonth();
          if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) return age - 1;
          return age;
        })();
        return `（${age}歲）`;
      },
    },
    methods: {
      print() {
        this.chartBase64Image = this.chart.toBase64Image();
        this.$nextTick(() => {
          this.$htmlToPaper('for-printing');
        });
      },
    },
  }),
);
</script>
